.content {
  .submenu--sticky.appointment-nav {
    z-index: 990;
    &.collapse {
      .submenu__nav {
        display: block;
      }
    }
  }
  .tout-block-landscape {
    margin-top: 8rem;
  }
}

.elc-appt-booking_store-selection-container {
  .elc-appt-booking_mobile-store-selector {
    div {
      &:first-child {
        width: 100%;
      }
    }
  }
}

.appt-book {
  .my-appointments {
    .appointments-container {
      .section-header {
        .section-header__inner {
          font-weight: 400;
        }
      }
    }
  }
}
